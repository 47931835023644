<template>
    <v-row no-gutters>

        <v-col cols="12" xs="12" md="12">
            <v-data-table
                :loading="loading"
                :headers="headers"
                :items="entities"
                :options.sync="options"
                :server-items-length="total"
                :items-per-page="10"
                :footer-props="{ itemsPerPageOptions:[5, 10, 20] }"
                :page.sync="options.page"
                @page-count="paginationPageCount = $event"
                @dblclick:row="rowClick"
                class="elevation-1 customization-list-table pagination-list-table sticky-table">

                <v-progress-linear slot="progress" height="2" color="#E43958" indeterminate></v-progress-linear>

                <template v-slot:[`item.name`]="{ item }">
                    <div class="elipsis-text-wrap">
                        <div class="etw-text-container">
                            <div class="etw-text" v-tooltip.top-center="item.name">
                                {{item.name}}
                            </div>
                        </div>
                    </div>
                </template>

                <template v-slot:[`footer.prepend`]>
                    <v-pagination
                        v-model="options.page"
                        :length="paginationPageCount"
                        :total-visible="$listPaginationTotal"
                        color="teal"
                    ></v-pagination>
                </template>

            </v-data-table>
        </v-col>

    </v-row>
</template>

<script>
import i18n from '@/i18n'
import Axios from 'axios';
import { httpAPI } from "@/api/httpAPI";
import sys from "@/services/system";

export default {
    columnIndex:  {
        'name': 3,
    },
    props: {
        search: {
            type: String,
            default: ""
        }
    },
    data() {
        return {
            loading: true,
            cancellationTokenSorce: null,
            headers: [
                { text: i18n.t("Наименование"), value: 'name' },
            ],
            total: 0,            
            entities: [],
            prevTableFilterObject: null,
            paginationPageCount: 0,
        }
    },
    computed: {
        options: {
            get: function() {
                return this.$store.getters['emsrefs/roles/GetTableOptions'];
            },
            set: function(newValue) {
                this.$store.commit('emsrefs/roles/SET_TABLE_OPTION', newValue);
            }
        },
        filter () {
            return {
                ...this.options,
                SearchStr: this.search
            }
        }
    },
    methods: {
        async getData(isRefresh = false) {
            if (isRefresh === false && this.$_.isEqual(this.prevTableFilterObject, this.getSerializedFilter()))
                return;

            this.prevTableFilterObject = this.getSerializedFilter();
            this.total = 0;
            this.entities = [];
            this.loading = true;
            if (this.cancellationTokenSorce)
                this.cancellationTokenSorce.cancel('New request started');

            this.cancellationTokenSorce = Axios.CancelToken.source();

            let response = await httpAPI({
                url: `api/ems/roleslist?filter=${this.getSerializedFilter()}`,
                method: 'GET',
                cancelToken: this.cancellationTokenSorce.token,
            });
            this.entities = response?.data?.payload?.data ?? [];
            this.total = response?.data?.payload?.total ?? 0;
            this.loading = false;
        },
        getSerializedFilter() {
            let { sortBy, sortDesc, page, itemsPerPage, SearchStr } = this.filter;

            let OrderDir = sortDesc.length <= 0 ? false : sortDesc[0];
            let sortColumn = sortBy.length <= 0 ? "regdate" : sortBy[0];
            let OrderBy = this.$options.columnIndex[sortColumn] ?? 4;

            return JSON.stringify(
            { 
                SearchStr,
                OrderBy,
                OrderDir,
                Limit: itemsPerPage,
                Offset: (page - 1) * itemsPerPage
            });
        },
        dateFormat: sys.dateFormat,
        async onToolbarClick(event, btn) {
            this[btn.Action](btn);
        },
        rowClick(item, row) {
            this.$router.push({ name: 'EMSRoleCard', query: { id: this.entities[row.index].id }, params: { id: this.entities[row.index].id } });
        }
    },
    watch: {
        filter: {
            handler () {
                this.getData()
            },
            deep: true,
        },
    },
}
</script>
