<template>
    <v-dialog
        v-if="visible"
        v-model="visible"
        transition="dialog-top-transition"
        persistent
        max-width="700"
    >
        <v-card class="modal-maincard">

            <v-toolbar dark flat dense>
                <v-toolbar-title>{{ $t(title) }}</v-toolbar-title>
            </v-toolbar>

            <v-card-text class="wrapperFormModal">
                <v-card
                    flat
                >
                    <v-card-text>
                        <!--
                        <v-row no-gutters>
                            <v-col cols="12" sm="12" md="3">
                                <label class="f-label">{{ $t("Код") }}</label>
                            </v-col>

                            <v-col cols="12" sm="12" md="9">
                                <v-text-field
                                    :value="code"
                                    @input="$store.commit('emsDialogs/addJobTitle/SET_CODE', $event)"
                                    hide-details
                                    required
                                    outlined
                                    dense
                                    maxlength="6"
                                    @keydown.native="onCodeKeyDown"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                        -->
                        <v-row no-gutters>
                            <v-col cols="12" sm="12" md="3">
                                <label class="f-label">{{ $t("Наименование") }}</label>
                            </v-col>

                            <v-col cols="12" sm="12" md="9">
                                <v-text-field
                                    :value="name"
                                    @input="$store.commit('emsDialogs/addJobTitle/SET_NAME', $event)"
                                    hide-details
                                    required
                                    outlined
                                    dense
                                ></v-text-field>
                            </v-col>
                        </v-row>

                        <v-row no-gutters>
                            <v-col cols="12" sm="12" md="3">
                                <label class="f-label">{{ $t("Краткое_наименование") }}</label>
                            </v-col>

                            <v-col cols="12" sm="12" md="9">
                                <v-text-field
                                    :value="shortname"
                                    @input="$store.commit('emsDialogs/addJobTitle/SET_SHORTNAME', $event)"
                                    hide-details
                                    required
                                    outlined
                                    dense
                                ></v-text-field>
                            </v-col>
                        </v-row>
                        
                    </v-card-text>

                </v-card>
            </v-card-text>

            <v-card-actions>
                <v-spacer></v-spacer>

                <v-btn 
                    color="cyan"
                    text
                    depressed
                    @click="ok"
                    v-if="name"
                >
                    {{ $t("Сохранить") }}
                </v-btn>

                <v-btn 
                    color="blue-grey"
                    text
                    depressed
                    @click="cancel"
                >
                    {{ $t("Отмена") }}
                </v-btn>
            </v-card-actions>

        </v-card>
    </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
    name: "AddJobTitleDlg",
    specialKeys: [ 8 ],
    data: () => ({
        title: "Должность",
    }),
    computed: {
        ...mapGetters('emsDialogs/addJobTitle',
        {
            visible: 'isVisible',
            //code: 'getCode',
            name: 'getName',
            shortname: 'getShortname',
        }),
    },
    methods: {
        ...mapActions('emsDialogs/addJobTitle', [ 'ok', 'cancel' ]),
        /*onCodeKeyDown(event) {
            if (event.which === 65 && event.ctrlKey )
                return true;

            let keyCode = event.which ? event.which : event.keyCode;            
            let allow = ((keyCode >= 48 && keyCode <= 57) || (keyCode >= 96 && keyCode <= 105)) || this.$options.specialKeys.includes(keyCode);

            event.returnValue = allow;
        }*/
    }
}
</script>